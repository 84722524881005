<template>
  <el-select
    ref="select_element"
    v-model="value"
    :clearable="clearable"
    @clear="resetForm"
    :filterable="searchable"
    @change="handleSelect"
    placeholder="请输入"
    cellclassname="carGroupSelect"
  >
    <el-option
      v-for="item in options"
      :key="item[label]"
      :label="`${item.groupName}_${item.parentName}`"
      :value="item[label]"
    ></el-option>
  </el-select>
</template>

<script>
/**
 * 选择车队组件   模糊搜索 单选
 *
 */
import { queryImportCompanyGroups } from '@/api/lib/api.js'
export default {
  props: {
    label: {
      type: String,
      default: 'groupId'
    },
    /* 已选中 */
    choosedCph: {
      type: String,
      default: ''
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true
      }
    },

    searchable: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },

  data() {
    return {
      value: null, // 初始值
      options: [],
      loading: false
    }
  },

  methods: {
    /** 清空input */
    resetForm() {
      this.value = null
      this.$emit('getData', null)
    },

    /** 获取车队 */
    filterGroups() {
      queryImportCompanyGroups().then((res) => {
        if (res.code === 1000) {
          this.options = res.data
        } else {
          this.options = []
        }
      })
    },
    handleSelect() {
      this.$emit('getData', this.value)
    }
  },
  created() {},
  mounted() {
    this.filterGroups()
  },
  watch: {}
}
</script>
