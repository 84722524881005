var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-file" },
    [
      _c("p", { staticClass: "form-title form-title2" }, [
        _vm._v(" 1.下载模板，并根据模板中的要求完善要导入的文件 ")
      ]),
      _c(
        "section",
        { staticClass: "button-box" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.downLoadStatus,
                icon: "el-icon-download"
              },
              on: { click: _vm.onExport }
            },
            [_vm._v("点击下载导入模板")]
          )
        ],
        1
      ),
      !_vm.notShowCompany
        ? _c("p", { staticClass: "form-title form-title2" }, [
            _vm._v(" 2.请选择要导入的车辆" + _vm._s(_vm.companyName) + " ")
          ])
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.addForm,
            inline: true,
            size: "small",
            rules: _vm.formRules
          }
        },
        [
          !_vm.notShowCompany
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.companyName + "：", prop: "companyId" } },
                [
                  _vm.type === 0
                    ? _c("carGroupSelect", { on: { getData: _vm.getGroupId } })
                    : _c("companySelectForSearch", {
                        ref: "companySelectForSearch",
                        attrs: { multiple: false, searchable: true },
                        on: { getValue: _vm.getGroupIds }
                      })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("p", { staticClass: "form-title form-title2" }, [
        _vm._v(" " + _vm._s(_vm.notShowCompany ? 2 : 3) + ".上传导入文件 ")
      ]),
      _c(
        "section",
        { staticClass: "button-box" },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload",
              attrs: {
                multiple: false,
                "auto-upload": true,
                "on-error": _vm.uploadFalse,
                "on-success": _vm.uploadSuccess,
                "before-upload": _vm.beforeUpload,
                data: _vm.addForm,
                action: _vm.uploadUrl(),
                headers: _vm.getHeader(),
                limit: 1
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-upload"
                  }
                },
                [_vm._v(_vm._s(_vm.name) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.notShowCompany
        ? _c("section", { staticClass: "button-box" }, [
            _c("p", [
              _vm._v("备注：1." + _vm._s(_vm.remarks[_vm.type].split("_")[0]))
            ]),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.remarks[_vm.type].split("_")[1]
                      ? "2. " + _vm.remarks[_vm.type].split("_")[1]
                      : ""
                  ) +
                  " "
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }